import React, {useRef} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import {Button} from '@mui/material';
import {useDispatch} from 'react-redux';

function PropertyEditNodeListDelete({ open, node, handleClose, handleDelete }) {
  const dispatch = useDispatch();

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>Delete virtual tour node</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          <p>Are you sure you want to delete the node {node.name}?</p>
          <p>Deleting a node is permanent and can not be undone.</p>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          onClick={handleDelete}
          color="warning"
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PropertyEditNodeListDelete;
