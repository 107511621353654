import React from 'react';
import Logo from '../common/logo/Logo';
import './css/tourNotAvailable.scss';

function TourNotAvailable(props) {
  return (
    <div className="tour-not-available">
      <Logo />
      <h1>Virtual tour not available</h1>
    </div>
  );
}

export default TourNotAvailable;
