import React, {useRef} from 'react';
import {Button} from '@mui/material';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import {signIn} from '../../../library/redux/actions/authActions';
import {useDispatch, useSelector} from 'react-redux';

import './css/auth.scss';
import Logo from '../../common/logo/Logo';

const emailErrors = {};
emailErrors['auth/user-not-found'] = 'Email does not exist.';
emailErrors['auth/invalid-email'] = 'Invalid email address.';
emailErrors['auth/internal-error'] = 'Internal Error. Try again later.';

const passwordErrors = {};
passwordErrors['auth/wrong-password'] = 'Wrong password.';


function Login({ children }) {
  const dispatch = useDispatch();

  const currentUser = useSelector(store => store.auth.currentUser);
  const error = useSelector(store => store.auth.signinError);

  const email = useRef();
  const password = useRef();

  const errorMsg = (error, errorList) => {
    const e = errorList[error];
    return !!e ? e : null;
  };

  return (
    <div className="login">
      <Card className="login_card">
        <div className="logo">
          <Logo width={200}/>
        </div>
        <TextField
          type="email"
          label="Email"
          variant="outlined"
          inputRef={email}
          helperText={errorMsg(error, emailErrors)}
          error={!!errorMsg(error, emailErrors)}
        />
        <TextField
          type="password"
          label="Password"
          variant="outlined"
          inputRef={password}
          helperText={errorMsg(error, passwordErrors)}
          error={!!errorMsg(error, passwordErrors)}
        />
        <Button
          variant="contained"
          onClick={() => {
            dispatch(signIn(email.current.value, password.current.value));
          }}
        >
          Login
        </Button>
      </Card>
    </div>
  );
}

export default Login;
