import React, {useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import './css/propertyList.scss';
import {doc, getFirestore, setDoc, collection} from 'firebase/firestore';
import {requestPropertyList} from '../../library/redux/actions/propertiesActions';
import {FormControlLabel, Switch} from '@mui/material';


function PropertyAdd(props) {
  const dispatch = useDispatch();

  const name = useRef();
  const folderName = useRef();
  const link = useRef();
  const [showRemaxLogo, setShowRemaxLogo] = useState(false);
  const [showAwaitingsunLogo, setShowAwaitingsunLogo] = useState(false);

  const [addPropertyDialogVisibility, setAddPropertyDialogVisibility] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const currentUser = useSelector(store => store.auth.currentUser)

  const handleClose = () => {
    setAddPropertyDialogVisibility(false);
  }

  const handleAddProperty = async () => {
    setIsSending(true);
    const db = getFirestore();

    const newDoc = doc(collection(db, "properties"));

    await setDoc(newDoc, {
      author: currentUser.uid,
      name: name.current.value,
      status: 'private',
      tour: [],
      creationDate: Date.now(),
      folderName: folderName.current.value,
      link: link.current.value,
      showRemaxLogo: showRemaxLogo,
      showAwaitingsunLogo: showAwaitingsunLogo,
    });

    dispatch(requestPropertyList());
    setIsSending(false);
    setAddPropertyDialogVisibility(false);
  }

  return (
    <div className="property_add">
      <Button
        startIcon={<AddIcon />}
        onClick={() => setAddPropertyDialogVisibility(true)}
      >
        Add new property
      </Button>
      <Dialog open={addPropertyDialogVisibility} onClose={handleClose}>
        <DialogTitle>Add new property</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter a display name for the new property.
          </DialogContentText>
          <TextField
            inputRef={name}
            autoFocus
            margin="dense"
            id="name"
            label="Property name"
            fullWidth
            variant="outlined"
          />
          <TextField
            inputRef={folderName}
            margin="dense"
            id="name"
            label="Folder Name"
            fullWidth
            variant="outlined"
          />
          <TextField
            inputRef={link}
            margin="dense"
            id="name"
            label="Link to property"
            fullWidth
            variant="outlined"
          />
          <FormControlLabel
            control={
              <Switch
                checked={showRemaxLogo}
                onChange={() => setShowRemaxLogo(!showRemaxLogo)}
              />}
            label="Show Remax Logo"
          />
          <FormControlLabel
            control={
              <Switch
                checked={showAwaitingsunLogo}
                onChange={() => setShowAwaitingsunLogo(!showAwaitingsunLogo)}
              />}
            label="Show Awaitingsun Logo"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            Cancel
          </Button>
          <Button
            onClick={handleAddProperty}
            disabled={isSending}
          >
            Add Property
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default PropertyAdd;
