import React from 'react';
import Logo from '../common/logo/Logo';
import './css/welcome.scss';

function Welcome(props) {
  return (
    <div className="welcome">
      <Logo />
    </div>
  );
}

export default Welcome;
