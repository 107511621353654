import React, {useRef} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import {Button} from '@mui/material';
import {getFirestore} from 'firebase/firestore';
import {useDispatch} from 'react-redux';
import { doc, deleteDoc } from "firebase/firestore";
import {requestPropertyList} from '../../library/redux/actions/propertiesActions';

function PropertyDelete({ open, property, handleClose }) {
  const dispatch = useDispatch();
  const isDeleting = useRef(false);

  const handleDelete = async () => {
    if (!isDeleting.current) {
      isDeleting.current = true;
      const db = getFirestore();

      await deleteDoc(doc(db, "properties", property.id));
      isDeleting.current = false;
      handleClose();
      dispatch(requestPropertyList());
    }
  }

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>Delete property</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          <p>Are you sure you want to delete the property {property.data.name}?</p>
          <p>Deleting a property is permanent and can not be undone. To prevent a property from beein shown to the customers deactivate it.</p>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          onClick={handleDelete}
          color="warning"
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

PropertyDelete.propTypes = {
  open: PropTypes.bool.isRequired,
  property: PropTypes.shape({
    id: PropTypes.string,
    data: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default PropertyDelete;
