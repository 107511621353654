import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import {generateRandomString} from '../../../library/generalFunction';
import CardMedia from '@mui/material/CardMedia';


function PropertyEditNodeListAdd({ folder, nextId, onAdd }) {
  const dispatch = useDispatch();

  const defaultValue = `/virtualtours/${folder}/${nextId.toString().padStart(3, '0')}.jpg`;

  const name = useRef();
  const [panoramaPath, setPanoramaPath] = useState(defaultValue);

  const [addNodeDialogVisibility, setAddNodeDialogVisibility] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const handleClose = () => {
    setAddNodeDialogVisibility(false);
  }

  const handleAddNode = () => {
    onAdd({
      id: generateRandomString(10),
      panorama: panoramaPath,
      panoData: { poseHeading: 327 },
      links: [],
      markers: [],
      name: name.current.value,
    });
    setAddNodeDialogVisibility(false);
  }

  return (
    <div className="property_add">
      <Button
        startIcon={<AddIcon />}
        onClick={() => setAddNodeDialogVisibility(true)}
      >
        Add new virtual tour node
      </Button>
      <Dialog open={addNodeDialogVisibility} onClose={handleClose}>
        <DialogTitle>Add new virtual tour node</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter a display name and a panorama url for the new node.
          </DialogContentText>
          <TextField
            inputRef={name}
            autoFocus
            margin="dense"
            id="name"
            label="Node name"
            fullWidth
            variant="outlined"
          />
          <TextField
            value={panoramaPath}
            defaultValue={defaultValue}
            margin="dense"
            id="panorama"
            label="Panorama url"
            fullWidth
            variant="outlined"
            onChange={(e) => setPanoramaPath(e.target.value)}
          />
          <CardMedia
            component="img"
            height="150"
            image={panoramaPath}
            alt="panorama"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            Cancel
          </Button>
          <Button
            onClick={handleAddNode}
            disabled={isSending}
          >
            Add Node
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default PropertyEditNodeListAdd;
