import React from 'react';
import './css/logoOverlay.scss';

function LogoOverlay({ property }) {
  const hasLink = property.link
    && property.link.length > 0
    && !(property.showRemaxLogo && property.showAwaitingsunLogo);

  const renderLogo = (logo) => {
    if(hasLink) {
      return (
        <a href={property.link} target="_blank" rel="noreferrer">
          <div className="container">
            {logo}
          </div>
        </a>
      );
    }

    return (
      <div className="container">
        {logo}
      </div>
    );
  }

  return (
    <div className="logo-overlay">
      {property.showAwaitingsunLogo && (
        renderLogo(<img src="/images/awaitingsun.svg" alt="logo" />)
      )}
      {property.showRemaxLogo && (
        renderLogo(<img src="/images/remax-logo.png" alt="logo"/>)
      )}
    </div>
  );
}

LogoOverlay.propTypes = {};

export default LogoOverlay;
