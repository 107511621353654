import React, {useEffect, useState} from 'react';
import {initializeApp} from 'firebase/app';
import {doc, getDoc, getFirestore} from "firebase/firestore";
import VirtualTour from './VirtualTour';
import {useParams} from 'react-router-dom';
import TourNotAvailable from './TourNotAvailable';


function StandaloneViewer({ tourId }) {
  const [property, setProperty] = useState(null);
  const [tourNotAvailable, setTourNotAvailable] = useState(false);

  const paramId = useParams().id;
  const node = useParams().node;
  const id = paramId || tourId;

  const getProperty = async () => {
    const db = getFirestore();

    const docRef = doc(db, "properties", id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setProperty(docSnap);
      console.log("Document data:", docSnap.data());
      if (docSnap.data().status === 'private') {
        setTourNotAvailable(true);
      }
    } else {
      setTourNotAvailable(true);
    }
  };

  useEffect(() => {
    const firebaseConfig = {
      apiKey: "AIzaSyAOQ0GjiLbMlHAyJC6y1--pqjPJ1u7YVMA",
      authDomain: "awaitingsun.firebaseapp.com",
      projectId: "awaitingsun",
      storageBucket: "awaitingsun.appspot.com",
      messagingSenderId: "811883311147",
      appId: "1:811883311147:web:043c0b36ab3535caf47a6e",
      measurementId: "G-BYWGFERFW1"
    };

    initializeApp(firebaseConfig);
    getProperty();
  }, []);

  return (
    <div>
      {property && !tourNotAvailable && (
        <div className="virtual_tour_container">
          <VirtualTour
            size={{ width: '100%', height: '100vh' }}
            tour={property.data().tour}
            onClickInSzene={() => {}}
            startNode={node || null}
            tourId={id}
            property={property.data()}
          />
        </div>
      )}
      {tourNotAvailable && (
        <TourNotAvailable />
      )}
    </div>
  );
}

export default StandaloneViewer;
