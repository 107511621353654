import { clone, assoc } from 'ramda';

const defaultState = {
  currentUser: null,
  signinError: null,
};

const authReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_CURRENT_USER':
      return assoc('currentUser', action.payload, state);
    case 'SET_SIGNIN_ERROR':
      return assoc('signinError', action.payload, state);
    case 'RESET_STATE_TO_DEFAULT':
      return clone(defaultState);
    default:
      return state;
  }
};

export default authReducer;
