import React, {useEffect, useRef, useState} from 'react';
import { Viewer } from '@photo-sphere-viewer/core';
import { MarkersPlugin } from '@photo-sphere-viewer/markers-plugin';
import '@photo-sphere-viewer/core/index.css';
import '@photo-sphere-viewer/markers-plugin/index.css';
import {append, assoc, assocPath, clone, find, findIndex, propEq, remove, update} from 'ramda';
import {generateRandomString} from '../../library/generalFunction';
import PanoramaEditorAddEditMarker from './PanoramaEditorAddEditMarker';
import {VIRTUAL_TOUR_MARKER_HTML} from './VirtualTour';

function PanoramaEditor({ onUpdate, panorama, nodeList }) {
  const panoAnchor = useRef();
  const [psv, setPsv] = useState();
  const [addEditMarker, setAddEditMarker] = useState(null);

  const plugins = [
    [MarkersPlugin, {
      markers: panorama.markers,
    }],
  ]

  const handleClick = ({ data }) => {
    setAddEditMarker({
      id: generateRandomString(10),
      html: VIRTUAL_TOUR_MARKER_HTML,
      size: {width: 80, height: 80 },
      position: { textureX: data.textureX, textureY: data.textureY },
    });
  }

  const handleMarkerClick = ({ marker }) => {
    setAddEditMarker(marker.config);
  }

  useEffect(() => {
    const viewer = new Viewer({
      container: panoAnchor.current,
      panorama: panorama.data.image,
      size: { width: '100%', height: 'calc(100vh - 120px)' },
      plugins: plugins,
      defaultZoomLvl: 20,
      navbar: ['fullscreen'],
    });

    const markersPlugin = viewer.getPlugin(MarkersPlugin);

    setPsv(viewer);

    return () => {
      viewer.destroy();
    }
  }, []);

  useEffect(() => {
    if (psv) {
      const markersPlugin = psv.getPlugin(MarkersPlugin);

      markersPlugin.setMarkers(panorama.markers);
      psv.addEventListener('click', handleClick);
      markersPlugin.addEventListener('select-marker', handleMarkerClick);
    }

    return () => {
      if (psv) {
        psv.removeEventListener('click', handleClick);

        const markersPlugin = psv.getPlugin(MarkersPlugin);
        if (markersPlugin) {
          markersPlugin.removeEventListener('select-marker', handleMarkerClick);
        }
      }
    }
  }, [panorama.markers, psv]);


  return (
    <div className="panorama_viewer">
      <div className="pano_anchor" ref={panoAnchor} />
      <PanoramaEditorAddEditMarker
        isOpen={!!addEditMarker}
        marker={addEditMarker}
        onCancel={() => setAddEditMarker(null)}
        isEdit={addEditMarker && !!find(propEq('id', addEditMarker.id))(panorama.markers)}
        nodeList={nodeList}
        onSubmit={(data) => {
          const markerIndex = findIndex(propEq('id', data.id))(panorama.markers);
          const markers = (markerIndex !== -1)
            ? update(markerIndex, data, panorama.markers)
            : append(data, panorama.markers);

          console.log(markers);
          onUpdate(assoc('markers', markers, panorama));

          setAddEditMarker(null);
        }}
        onDelete={() => {
          const markerIndex = findIndex(propEq('id', addEditMarker.id))(panorama.markers);
          const markers = remove(markerIndex, 1, panorama.markers);
          onUpdate(assoc('markers', markers, panorama));
          setAddEditMarker(null);
        }}
      />
    </div>
  );
}

PanoramaEditor.propTypes = {};

export default PanoramaEditor;
