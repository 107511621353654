import React, {useState} from 'react';
import PropTypes from 'prop-types';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Card from '@mui/material/Card';
import Avatar from '@mui/material/Avatar';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import {Typography} from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import PropertyEditNodeListDelete from './PropertyEditNodeListDelete';
import PropertyEditNodeListSetLinksAndMarkers from './PropertyEditNodeListSetLinksAndMarkers';
import PropertyEditNodeListEdit from './PropertyEditNodeListEdit';

export const searchForLinksToNode = (nodeList, nodeId) => {
  const nodes = [];
  nodeList.forEach(node => {
    node.links.forEach(link => {
      if (link.nodeId === nodeId) {
        nodes.push(node);
      }
    });
  });
  return nodes;
}

export const hasLinksToNode = (links, nodeId) => {
  return links.some(link => link.nodeId === nodeId);
}

export const searchUnresolvedLinksToNode = (nodeList, node) => {
  const nodes = searchForLinksToNode(nodeList, node.id);
  const unresolvedLinks = [];

  nodes.forEach(n => {
    if (!hasLinksToNode(node.links, n.id)) {
      unresolvedLinks.push(n);
    }
  });

  console.log('unresolvedLinks', unresolvedLinks);
  console.log('node.links', node.links);
  console.log('links', nodes);

  return unresolvedLinks;
}

function PropertyEditNodeListEntry({ node, nodeList, onDelete, onUpdate }) {
  const navigate = useNavigate();

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showEditLinksDialog, setShowEditLinksDialog] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const showOptions = !!anchorEl;

  const handleCloseOptions = () => {
    setAnchorEl(null);
  };

  const unresolvedLinks = searchUnresolvedLinksToNode(nodeList, node);

  return (
    <Card className="property_list_entry">
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: '#FF0000' }} aria-label="recipe">
            R
          </Avatar>
        }
        action={
          <IconButton
            aria-label="settings"
            onClick={e => setAnchorEl(e.currentTarget)}
          >
            <MoreVertIcon />
          </IconButton>
        }
        title={node.name}
        subheader="Other Info"
      />
      <CardMedia
        component="img"
        height="194"
        image={node.panorama}
        alt="Property image"
      />
      <CardContent>
        <Typography variant="body2" color="text.secondary">
          {`${node.links.length} Links / ${node.markers.length} Markers`}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {`${unresolvedLinks.length} Unresolved backlinks`}
        </Typography>
      </CardContent>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={showOptions}
        onClose={handleCloseOptions}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          onClick={() => {
            setShowEditDialog(true);
            handleCloseOptions();
          }}
        >
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            setShowEditLinksDialog(true);
            handleCloseOptions();
          }}
        >
          Set Links and Markers
        </MenuItem>
        <MenuItem
          onClick={() => {
            setShowDeleteDialog(true);
            handleCloseOptions();
          }}
        >
          Delete
        </MenuItem>
      </Menu>
      <PropertyEditNodeListEdit
        open={showEditDialog}
        node={node}
        onEdit={(data) => {
          onUpdate(data);
          setShowEditDialog(false);
        }}
        handleClose={() => setShowEditDialog(false)}
      />
      <PropertyEditNodeListDelete
        handleClose={() => setShowDeleteDialog(false)}
        handleDelete={() => {
          setShowDeleteDialog(false);
          onDelete(node);
        }}
        open={showDeleteDialog}
        node={node}
      />
      <PropertyEditNodeListSetLinksAndMarkers
        open={showEditLinksDialog}
        node={node}
        nodeList={nodeList}
        handleSave={(data) => {
          onUpdate(data);
          setShowEditLinksDialog(false);
        }}
        handleClose={() => setShowEditLinksDialog(false)}
      />
    </Card>
  );
}

export default PropertyEditNodeListEntry;
