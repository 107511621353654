import React, {useEffect, useRef, useState} from 'react';
import { Viewer } from '@photo-sphere-viewer/core';
import { MarkersPlugin } from '@photo-sphere-viewer/markers-plugin';
import { VirtualTourPlugin } from '@photo-sphere-viewer/virtual-tour-plugin';
import '@photo-sphere-viewer/core/index.css';
import '@photo-sphere-viewer/markers-plugin/index.css';
import '@photo-sphere-viewer/virtual-tour-plugin/index.css';
import './css/virtualTour.scss';
import VirtualTourControl from './VirtualTourControl';
import LogoOverlay from './LogoOverlay';


export const VIRTUAL_TOUR_LINK_HTML = '<div class="custom_marker"><div class="inner"><img src="/images/arrow-circle-up.svg" /></div></div>';
export const VIRTUAL_TOUR_MARKER_HTML = '<div class="custom_marker"><div class="inner"><img src="/images/info-circle.svg" /></div></div>';

function VirtualTour({ onClickInSzene, size, tour, startNode, tourId, property }) {
  const panoAnchor = useRef();
  const [psv, setPsv] = useState();
  const [currentNode, setCurrentNode] = useState(null);

  console.log(startNode)

  const plugins = [
    [MarkersPlugin, {}],
    [VirtualTourPlugin, {
      renderMode: 'markers',
      markerStyle: {
        html: VIRTUAL_TOUR_LINK_HTML,
        size: { width: 80, height: 80 },
      },
    }],
  ]

  const handleClick = ({ data }) => {
    onClickInSzene(data);
  }

  useEffect(() => {
    const viewer = new Viewer({
      container: panoAnchor.current,
      size: size,
      plugins: plugins,
      defaultZoomLvl: 20,
      maxFov: 55,
      minFov: 55,
      navbar: [],
    });

    const markersPlugin = viewer.getPlugin(MarkersPlugin);
    const virtualTour = viewer.getPlugin(VirtualTourPlugin);
    virtualTour.setNodes(tour);
    virtualTour.addEventListener('node-changed', ({ node, data }) => {
      setCurrentNode(node);
    });

    if (startNode) virtualTour.setCurrentNode(startNode);

    setPsv(viewer);

    return () => {
      viewer.destroy();
    }
  }, [tour]);


  return (
    <div className="panorama_viewer standalone_viewer">
      <div className="pano_anchor" ref={panoAnchor} />
      <VirtualTourControl
        nodes={tour}
        currentNode={currentNode}
        changeNode={(node) => psv.getPlugin(VirtualTourPlugin).setCurrentNode(node)}
        tourId={tourId}
      />
      {property && <LogoOverlay property={property} />}
    </div>
  );
}

export default VirtualTour;
