import React, {useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import './css/propertyList.scss';
import {doc, getFirestore, setDoc, collection} from 'firebase/firestore';
import {requestPropertyList} from '../../library/redux/actions/propertiesActions';
import {FormControlLabel, Switch} from '@mui/material';
import {assoc, compose} from 'ramda';


function PropertyEdit({ property, handleClose, open }) {
  const dispatch = useDispatch();

  const propertyData = property.data;

  console.log(propertyData);

  const [name, setName] = useState(propertyData.name);
  const [folderName, setFolderName] = useState(propertyData.folderName || '');
  const [link, setLink] = useState(propertyData.link || '');
  const [showRemaxLogo, setShowRemaxLogo] = useState(propertyData.showRemaxLogo || false);
  const [showAwaitingsunLogo, setShowAwaitingsunLogo] = useState(propertyData.showAwaitingsunLogo || false);

  console.log(showAwaitingsunLogo);

  const [isSending, setIsSending] = useState(false);

  const handleEditProperty = async () => {
    setIsSending(true);
    const db = getFirestore();
    const newDoc = doc(db, "properties", property.id);
    await setDoc(newDoc, compose(
      assoc('name', name),
      assoc('folderName', folderName),
      assoc('link', link),
      assoc('showRemaxLogo', showRemaxLogo),
      assoc('showAwaitingsunLogo', showAwaitingsunLogo),
    )(propertyData));

    dispatch(requestPropertyList());
    setIsSending(false);
    handleClose();
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Edit property</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please enter a display name for the new property.
        </DialogContentText>
        <TextField
          value={name}
          onChange={e => setName(e.target.value)}
          autoFocus
          margin="dense"
          id="name"
          label="Property name"
          fullWidth
          variant="outlined"
        />
        <TextField
          value={folderName}
          onChange={e => setFolderName(e.target.value)}
          margin="dense"
          id="name"
          label="Folder Name"
          fullWidth
          variant="outlined"
        />
        <TextField
          value={link}
          onChange={e => setLink(e.target.value)}
          margin="dense"
          id="name"
          label="Link to property"
          fullWidth
          variant="outlined"
        />
        <FormControlLabel
          control={
            <Switch
              checked={showRemaxLogo}
              onChange={() => setShowRemaxLogo(!showRemaxLogo)}
            />}
          label="Show Remax Logo"
        />
        <FormControlLabel
          control={
            <Switch
              checked={showAwaitingsunLogo}
              onChange={() => setShowAwaitingsunLogo(!showAwaitingsunLogo)}
            />}
          label="Show Awaitingsun Logo"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          Cancel
        </Button>
        <Button
          onClick={handleEditProperty}
          disabled={isSending}
        >
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PropertyEdit;
