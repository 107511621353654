import { clone, assoc } from 'ramda';

const defaultState = {
  list: null,
};

const propertiesReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_PROPERTIES_LIST':
      return assoc('list', action.payload, state);
    case 'RESET_STATE_TO_DEFAULT':
      return clone(defaultState);
    default:
      return state;
  }
};

export default propertiesReducer;
