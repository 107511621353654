import { clone, assoc } from 'ramda';

const defaultState = {
  app: null,
  auth: null,
  analytics: null,
  db: null,
};

const firebaseReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_FIREBASE_APP':
      return assoc('app', action.payload, state);
    case 'SET_FIREBASE_DB':
      return assoc('db', action.payload, state);
    case 'SET_FIREBASE_AUTH':
      return assoc('auth', action.payload, state);
    case 'SET_FIREBASE_ANALYTICS':
      return assoc('analytics', action.payload, state);
    case 'RESET_STATE_TO_DEFAULT':
      return clone(defaultState);
    default:
      return state;
  }
};

export default firebaseReducer;
