import './app.scss';
import {useStore} from '../library/redux/store';
import { Provider } from 'react-redux'
import Router from './Router';
import React from 'react';
import StandaloneViewer from './panoramaViewer/StandaloneViewer';

function App() {
  const store = useStore(true);

  if (store) {
    return (
      <Provider store={store}>
        <Router />
      </Provider>
    );
  }
}

export default App;
