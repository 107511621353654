import React from 'react';
import Fab from '@mui/material/Fab';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import PropTypes from 'prop-types';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import './css/virtualTourControl.scss';
import {List, ListItem} from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import {Share} from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import {VirtualTourPlugin} from '@photo-sphere-viewer/virtual-tour-plugin';

function VirtualTourControl({ nodes, changeNode, currentNode, tourId }) {
  const [isOpen, setIsOpen] = React.useState(false);

  const handleSelect = (node) => {
    changeNode(node.id);
    setIsOpen(false);
  }

  const handleShare = async () => {
    const currentDomain = window.location.hostname;
    const domain = currentDomain === 'localhost' ? 'http://localhost:3000' : `https://${currentDomain}`
    const url = `${domain}/tour/${tourId}/${currentNode.id}`;

    try {
      if (navigator.share) {
        await navigator.share({
          url: url,
        });
      } else {
        await navigator.clipboard.writeText(url);
      }
      console.log('Successful share or copy');
    } catch (error) {
      console.log('Error sharing or copying:', error);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  }

  return (
    <div className="virtual-tour-control">
      <Fab
        color="secondary"
        onClick={() => setIsOpen(true)}
      >
        <AutoAwesomeMotionIcon />
      </Fab>
      <Dialog open={isOpen} onClose={handleClose}>
        <DialogTitle>Fast travel</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Select a location where you want to fast travel to.
          </DialogContentText>
          <List>
            {nodes.map((node) => (
              <ListItem button onClick={() => handleSelect(node)} key={node.id}>
                <ListItemText primary={node.name} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            startIcon={<Share />}
            onClick={handleShare}
          >
            {navigator.share ? 'Share this location' : 'Copy this location'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

VirtualTourControl.propTypes = {};

export default VirtualTourControl;
