import { createStore, combineReducers, applyMiddleware } from 'redux';
import logger from 'redux-logger';
import promise from 'redux-promise-middleware';
import thunk from 'redux-thunk';
import { without } from 'ramda';
import {useEffect, useState} from 'react';
import authReducer from './reducers/authReducer';
import firebaseReducer from './reducers/firebaseReducer';
import propertiesReducer from './reducers/propertiesReducer';

export function useStore(debug = false) {
  const [store, setStore] = useState();

  useEffect(() => {
    const allMiddleware = [
      thunk,
      logger,
      promise,
    ];
    const debugMiddleware = [logger];
    const middleware = debug ? allMiddleware : without(debugMiddleware, allMiddleware);

    setStore(
      createStore(
        combineReducers({
          auth: authReducer,
          firebase: firebaseReducer,
          properties: propertiesReducer,
        }),
        {},
        applyMiddleware(...middleware),
      )
    );
  }, []);


  return store;
}
