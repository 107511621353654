import React, {useEffect, useState} from 'react';
import {collection, doc, getDoc, getFirestore, setDoc} from "firebase/firestore";
import { useParams } from 'react-router-dom';
import PropertyEditNodeList from './PropertyEditNodeList';
import {requestPropertyList} from '../../../library/redux/actions/propertiesActions';
import {useDispatch} from 'react-redux';
import {assoc} from 'ramda';

function PropertyEditNodes(props) {
  const dispatch = useDispatch();

  const { id } = useParams();

  const [property, setProperty] = useState(null);

  const  getEntity = async () => {
    const db = getFirestore();

    const docRef = doc(db, "properties", id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setProperty(docSnap);
      console.log("Document data:", docSnap.data());
    } else {
      console.log("No such document!");
    }
  };

  const handleSaveProperty = async (data) => {
    const db = getFirestore();
    const newDoc = doc(db, "properties", property.id);
    await setDoc(newDoc, data);

    dispatch(requestPropertyList());
  }

  useEffect(() => {
    getEntity();
  }, []);

  if (property) {
    console.log(id);
    return (
      <div>
        <PropertyEditNodeList
          property={property.data()}
          id={id}
          onChange={(data) => {
            console.log(data);
            handleSaveProperty(assoc('tour', data, property.data()));
          }}
        />
      </div>
    );
  }

}

PropertyEditNodes.propTypes = {};

export default PropertyEditNodes;
