import React, {useEffect} from 'react';
import Login from './user/Auth/Login';
import Signup from './user/Auth/Signup';
import {RouterProvider, createBrowserRouter, createRoutesFromElements, Route, Navigate} from 'react-router-dom';
import Root from './Root';
import {useDispatch, useSelector} from 'react-redux';
import PropertyList from './properties/PropertyList';
import PropertyEditNodes from './properties/Edit/PropertyEditNodes';
import Welcome from './welcome/Welcome';
import PropertyView from './properties/PropertyView';
import {initFirebase} from '../library/redux/actions/firebaseActions';
import StandaloneViewer from './panoramaViewer/StandaloneViewer';

function Router(props) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initFirebase());
  }, []);

  const app = useSelector((store) => store.firebase.app);
  const currentUser = useSelector((store) => store.auth.currentUser);

  const loggedOutRoutes = [
    { path: '/editor/signin', element: <Login /> },
    { path: '/editor/', element: <Welcome /> },
  ]

  const loggedInRoutes = [
    { path: '/editor/edit/:id', element: <PropertyEditNodes /> },
    { path: '/editor/signin', element: <Navigate to="/editor/" replace />},
    { path: '/editor/', element: <PropertyList /> },
  ];

  const publicRoutes = [

  ];

  const rootChildren = currentUser ? [...loggedInRoutes] : [...loggedOutRoutes];
  const routes = [
    {
      path: '/',
      element: <Welcome />,
    },
    {
      path: '/editor/',
      element: <Root />,
      children: !!app ? rootChildren : [],
    },
    {
      path: '/tour/:id/:node',
      element: <StandaloneViewer />
    },
    {
      path: '/tour/:id',
      element: <StandaloneViewer />
    },
    {
      path: '*',
      element: <Navigate to="/" replace />,
    },
  ];

  const router = createBrowserRouter(routes);

  if (!!app) {
    return (
      <RouterProvider router={router} />
    );
  }

  return null;
}

export default Router;
