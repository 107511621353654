import {signInWithEmailAndPassword, getAuth, signOut} from 'firebase/auth';

export function signIn(email, password) {
  return (dispatch, getState) => {
    // Reset Error befor new request
    if (getState().auth.signinError) {
      dispatch({
        type: 'SET_SIGNIN_ERROR',
        payload: null,
      });
    }

    signInWithEmailAndPassword(getAuth(), email, password)
      .then((userCredential) => {
        dispatch({
          type: 'SET_CURRENT_USER',
          payload: userCredential.user,
        });
      }).catch((error) => {
        dispatch({
          type: 'SET_SIGNIN_ERROR',
          payload: error.code,
        });
      });
  };
}

export function logout() {
  return (dispatch) => {
    signOut(getAuth())
      .then(() => {
        dispatch({
          type: 'SET_CURRENT_USER',
          payload: null,
        });
      });
  };
}
