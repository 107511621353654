import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import './css/propertyEditNodeList.scss';
import PropertyEditNodeListAdd from './PropertyEditNodeListAdd';
import PropertyEditNodeListEntry from './PropertyEditNodeListEntry';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import ShareIcon from '@mui/icons-material/Share';
import Button from '@mui/material/Button';
import {append, findIndex, propEq, remove, update} from 'ramda';
import PropertyEditNodeListTestTour from './PropertyEditNodeListTestTour';

function PropertyEditNodeList({ property, id, onChange }) {
  const dispatch = useDispatch();

  const tour = property.tour;
  const folder = property.folderName;

  const [nodes, setNodes] = useState(tour);

  useEffect(() => {
    setNodes(tour);
  }, [tour]);

  return (
    <div className="property_list">
      <header>
        <PropertyEditNodeListAdd
          folder={folder}
          nextId={nodes.length + 1}
          onAdd={(data) => {
            setNodes(append(data, nodes));
          }}
        />
        <PropertyEditNodeListTestTour tour={nodes} />
        <Button
          startIcon={<ShareIcon />}
          onClick={() => {
            const currentDomain = window.location.hostname;
            const domain = currentDomain === 'localhost' ? 'http://localhost:3000' : `https://${currentDomain}`

            navigator.clipboard.writeText(`${domain}/tour/${id}`);
          }}
        >
          Copy tour link
        </Button>
        <Button
          startIcon={<SaveIcon />}
          onClick={() => onChange(nodes)}
          color="success"
        >
          Save Virtual Tour
        </Button>
      </header>
      <div className="list_container">
        <div className="list">
          {nodes && nodes.map(n => (
            <PropertyEditNodeListEntry
              node={n}
              nodeList={nodes}
              key={n.id}
              onDelete={(data) => {
                setNodes(remove(findIndex(propEq('id', data.id), nodes), 1, nodes));
              }}
              onUpdate={(data) => {
                setNodes(update(findIndex(propEq('id', data.id), nodes), data, nodes));
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default PropertyEditNodeList;
