import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from './header/Header';

function Root() {
  return (
    <div className="root" style={{marginTop: 40}}>
      <Header />
      <Outlet />
    </div>
  );
}

Root.propTypes = {};

export default Root;
