import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {requestPropertyList} from '../../library/redux/actions/propertiesActions';
import PropertyAdd from './PropertyAdd';

import './css/propertyList.scss';
import PropertyListEntry from './PropertyListEntry';

function PropertyList(props) {
  const dispatch = useDispatch();

  const properties = useSelector(s => s.properties.list);

  useEffect(() => {
    dispatch(requestPropertyList());
  }, []);

  return (
    <div className="property_list">
      <header>
        <PropertyAdd />
      </header>
      <div className="list_container">
        <div className="list">
          {properties && properties.map(p => (
            <PropertyListEntry property={p} key={p.id} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default PropertyList;
