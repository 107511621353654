import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';

import './css/header.scss';
import {Button} from '@mui/material';
import {logout} from '../../library/redux/actions/authActions';
import Logo from '../common/logo/Logo';
import {useNavigate} from 'react-router-dom';

function Header(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const currentUser = useSelector(store => store.auth.currentUser);

  return (
    <header className="primary_header">
      <button
        typeof="button"
        onClick={() => navigate('/editor')}
      >
        <Logo width={170}/>
      </button>
      <div className="user">
        {currentUser && (
          <Button
            onClick={() => {
              dispatch(logout());
            }}
          >
            Logout
          </Button>
        )}
        {!currentUser && (
          <Button
            onClick={() => {
              navigate(`/editor/signin`)
            }}
          >
            Sign in
          </Button>
        )}
      </div>
    </header>
  );
}

Header.propTypes = {};

export default Header;
