import React from 'react';
import './css/logo.scss';

function Logo({ width }) {
  return (
    <div className="awaitingsun_logo" style={{width}}>
      <img src="/images/awaitingsun.svg" alt="Logo"/>
    </div>
  );
}

export default Logo;
