import React, {useEffect, useRef, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import {Button} from '@mui/material';
import {useDispatch} from 'react-redux';
import PanoramaEditor from '../../panoramaViewer/PanoramaEditor';
import VirtualTour, {VIRTUAL_TOUR_LINK_HTML} from '../../panoramaViewer/VirtualTour';
import {assoc, clone, compose, concat, join} from 'ramda';
import {generateRandomString} from '../../../library/generalFunction';
import './css/propertyEditNodeListSetLinksAndMarkers.scss';
import VrpanoIcon from '@mui/icons-material/Vrpano';

function PropertyEditNodeListTestTour({ tour }) {
  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);

  console.log(tour);

  return (
    <div>
      <Button
        startIcon={<VrpanoIcon />}
        onClick={() => setOpen(true)}
      >
        Test Virtual Tour
      </Button>
      <Dialog
        open={open}
        keepMounted
        fullScreen
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Test the virtual tour</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {tour && (
              <div className="links_editor_dialog_panorama_container">
                {tour.length > 0 && (
                  <VirtualTour
                    tour={clone(tour)}
                    onClickInSzene={(data) => {}}

                  />
                )}
              </div>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default PropertyEditNodeListTestTour;
