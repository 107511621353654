import {initializeApp} from 'firebase/app';
import {getAuth} from 'firebase/auth';
import {getAnalytics} from 'firebase/analytics';
import {getFirestore} from "firebase/firestore";

export function initFirebase(data) {
  return (dispatch, getState) => {
    const firebaseConfig = {
      apiKey: "AIzaSyAOQ0GjiLbMlHAyJC6y1--pqjPJ1u7YVMA",
      authDomain: "awaitingsun.firebaseapp.com",
      projectId: "awaitingsun",
      storageBucket: "awaitingsun.appspot.com",
      messagingSenderId: "811883311147",
      appId: "1:811883311147:web:043c0b36ab3535caf47a6e",
      measurementId: "G-BYWGFERFW1"
    };

    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
    const auth = getAuth(app);
    const db = getFirestore(app);

    dispatch({
      type: 'SET_FIREBASE_ANALYTICS',
      payload: analytics,
    });

    dispatch({
      type: 'SET_FIREBASE_AUTH',
      payload: auth,
    });

    dispatch({
      type: 'SET_FIREBASE_DB',
      payload: db,
    });

    dispatch({
      type: 'SET_FIREBASE_APP',
      payload: app,
    });
  }
}
