import {collection, query, where, getDocs, orderBy} from "firebase/firestore";

export function requestPropertyList() {
  return async (dispatch, getState) => {
    const db = getState().firebase.db;

    const propertiesRef = collection(db, "properties");
    const q = query(
      propertiesRef,
      where("creationDate", ">=", 0),
      orderBy("creationDate", "desc")
    );

    const querySnapshot = await getDocs(q);

    const data = [];
    querySnapshot.forEach(doc => {
      data.push({
        id: doc.id,
        data: doc.data(),
        doc,
      });
    });

    dispatch({
      type: 'SET_PROPERTIES_LIST',
      payload: data,
    });
  };
}
