import React, {useState} from 'react';
import PropTypes from 'prop-types';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Card from '@mui/material/Card';
import Avatar from '@mui/material/Avatar';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import {Typography} from '@mui/material';
import CardActions from '@mui/material/CardActions';
import Menu from '@mui/material/Menu';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { useNavigate } from 'react-router-dom';
import './css/propertyListEntry.scss';
import PropertyDelete from './PropertyDelete';
import {collection, doc, getFirestore, setDoc} from 'firebase/firestore';
import {assoc, compose} from 'ramda';
import {requestPropertyList} from '../../library/redux/actions/propertiesActions';
import PropertyEdit from './PropertyEdit';
import {useDispatch} from 'react-redux';

function PropertyListEntry({ property }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const showOptions = !!anchorEl;

  const handleCloseOptions = () => {
    setAnchorEl(null);
  };

  const previewImage = (property && property.data.tour[0] && property.data.tour[0].panorama)
    || '/images/property_placeholder.svg';

  const getAwatarText = () => {
    if (property.data.showRemaxLogo && property.data.showAwaitingsunLogo) {
      return 'R/A';
    }
    if (property.data.showRemaxLogo) {
      return 'R';
    }
    if (property.data.showAwaitingsunLogo) {
      return 'A';
    }
    return 'N';
  };

  return (
    <Card className="property_list_entry">
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: property.data.status === 'private' ? '#d74747' : '#8aaf41' }} aria-label="recipe">
            {getAwatarText()}
          </Avatar>
        }
        action={
          <IconButton
            aria-label="settings"
            onClick={e => setAnchorEl(e.currentTarget)}
          >
            <MoreVertIcon />
          </IconButton>
        }
        title={property.data.name}
        subheader={property.data.folderName || 'No folder'}
      />
      <CardMedia
        component="img"
        height="194"
        image={previewImage}
        alt="Property image"
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={showOptions}
        onClose={handleCloseOptions}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          onClick={() => {
            navigate(`/editor/edit/${property.id}`)
            handleCloseOptions();
          }}
        >
          Build Tour
        </MenuItem>
        <MenuItem
          onClick={() => {
            setShowEditDialog(true);
            handleCloseOptions();
          }}
        >
          Edit
        </MenuItem>
        <MenuItem
          onClick={async () => {
            handleCloseOptions();
            const db = getFirestore();
            const newDoc = doc(db, "properties", property.id);
            await setDoc(newDoc, assoc('status', property.data.status === 'private' ? 'public' : 'private', property.data));
            dispatch(requestPropertyList());
          }}
        >
          {property.data.status === 'private' ? 'Activate' : 'Deactivate'}
        </MenuItem>
        <MenuItem
          onClick={async () => {
            handleCloseOptions();
            const db = getFirestore();
            const newDoc = doc(collection(db, "properties"));
            await setDoc(newDoc, property.data);
            dispatch(requestPropertyList());
          }}
        >
          Duplicate
        </MenuItem>
        <MenuItem
          onClick={() => {
            setShowDeleteDialog(true);
            handleCloseOptions();
          }}
        >
          Delete
        </MenuItem>
      </Menu>
      <PropertyDelete
        handleClose={() => setShowDeleteDialog(false)}
        open={showDeleteDialog}
        property={property}
      />
      <PropertyEdit
        handleClose={() => setShowEditDialog(false)}
        open={showEditDialog}
        property={property}
      />
    </Card>
  );
}

PropertyListEntry.propTypes = {
  property: PropTypes.shape({
    id: PropTypes.string,
    data: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
};

export default PropertyListEntry;
