import React from 'react';
import PropTypes from 'prop-types';

function Signup(props) {
  console.log('I am here');
  return (
    <div className="signups">
      SIGNUP
    </div>
  );
}

Signup.propTypes = {};

export default Signup;
