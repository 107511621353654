import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import {generateRandomString} from '../../../library/generalFunction';
import CardMedia from '@mui/material/CardMedia';
import {assoc, compose} from 'ramda';


function PropertyEditNodeListEdit({ onEdit, node, handleClose, open }) {
  const [name, setName] = useState(node.name);
  const [panoramaPath, setPanoramaPath] = useState(node.panorama);

  const handleEditNode = () => {
    onEdit(compose(
      assoc('name', name),
      assoc('panorama', panoramaPath),
    )(node));
    handleClose();
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Edit virtual tour node</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please enter a display name and a panorama url for the new node.
        </DialogContentText>
        <TextField
          value={name}
          autoFocus
          margin="dense"
          id="name"
          label="Node name"
          fullWidth
          variant="outlined"
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          value={panoramaPath}
          margin="dense"
          id="panorama"
          label="Panorama url"
          fullWidth
          variant="outlined"
          onChange={(e) => setPanoramaPath(e.target.value)}
        />
        <CardMedia
          component="img"
          height="150"
          image={panoramaPath}
          alt="panorama"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          Cancel
        </Button>
        <Button
          onClick={handleEditNode}
        >
          Edit Node
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PropertyEditNodeListEdit;
