import React, {useEffect, useRef, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import {Button} from '@mui/material';
import {useDispatch} from 'react-redux';
import PanoramaEditor from '../../panoramaViewer/PanoramaEditor';
import {VIRTUAL_TOUR_LINK_HTML} from '../../panoramaViewer/VirtualTour';
import {assoc, compose, concat, join} from 'ramda';
import {generateRandomString} from '../../../library/generalFunction';
import './css/propertyEditNodeListSetLinksAndMarkers.scss';
import {searchUnresolvedLinksToNode} from './PropertyEditNodeListEntry';

function PropertyEditNodeListSetLinksAndMarkers({ open, node, nodeList, handleClose, handleSave }) {
  const dispatch = useDispatch();

  const [panorama, setPanorama] = useState();

  useEffect(() => {
    const markers = concat(node.links.map((l) => {
      return {
        type: 'link',
        nodeId: l.nodeId,
        id: generateRandomString(6),
        html: VIRTUAL_TOUR_LINK_HTML,
        size: {width: 80, height: 80 },
        position: l.position,
        tooltip: 'Link',
      }
    }), node.markers);

    setPanorama({
      data: {
        image: node.panorama,
      },
      markers,
    });
  }, [node]);

  const unresolvedLinks = searchUnresolvedLinksToNode(nodeList, node);
  const unresolvedText = unresolvedLinks.length > 0 ? `Unresolved links: ${join(', ', unresolvedLinks.map((l) => l.name))}` : 'All links are resolved';

  return (
    <Dialog
      open={open}
      keepMounted
      fullScreen
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {unresolvedText}
        </DialogContentText>
        <DialogContentText id="alert-dialog-slide-description">
          {panorama && (
            <div className="links_editor_dialog_panorama_container">
              {open && (
                <PanoramaEditor
                  onUpdate={(data) => setPanorama(data)}
                  panorama={panorama}
                  nodeList={nodeList}
                />
              )}
            </div>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          onClick={() => {
            const markers = panorama.markers.filter((m) => m.type !== 'link');
            const linkMarkers = panorama.markers.filter((m) => m.type === 'link');

            const links = linkMarkers.map((l) => {
              return {
                nodeId: l.nodeId,
                position: l.position,
              };
            });

            handleSave(
              compose(
                assoc('markers', markers),
                assoc('links', links),
              )(node),
            );
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PropertyEditNodeListSetLinksAndMarkers;
