import React, {useEffect, useState, useRef} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import {append, assoc, clone, compose, dissoc, find, propEq} from 'ramda';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import {VIRTUAL_TOUR_LINK_HTML, VIRTUAL_TOUR_MARKER_HTML} from './VirtualTour';

const TAB_LINK = 0;
const TAB_MARKER = 1;

export default function PanoramaEditorAddEditMarker({marker, isOpen, onCancel, onSubmit, onDelete, isEdit, nodeList}) {
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  const [tab, setTab] = useState(1);
  const [tooltip, setTooltip] = useState('');
  const [linkTarget, setLinkTarget] = useState('');

  const [linkTargetError, setLinkTargetError] = useState();

  useEffect(() => {
    if (marker) {
      setTooltip(marker.tooltip ? marker.tooltip.content : '');
      setLinkTarget(marker.nodeId);
      setTab(marker.type === 'link' ? TAB_LINK : TAB_MARKER);
    }
  }, [marker, isOpen])

  const handleClose = () => {
    onCancel();
  };

  const handleSubmit = () => {
    let newMarker = clone(marker);

    if (tab === TAB_MARKER) {
      if (tooltip.length > 0) {
        newMarker = assoc('tooltip', tooltip, newMarker);
      } else {
        newMarker = dissoc('tooltip', newMarker);
      }
      newMarker = compose(
        assoc('html', VIRTUAL_TOUR_MARKER_HTML),
        dissoc('type'),
        dissoc('nodeId'),
      )(newMarker);
    }

    if (tab === TAB_LINK) {
      if (linkTarget) {
        newMarker = compose(
          assoc('nodeId', linkTarget),
          assoc('type', 'link'),
          assoc('html', VIRTUAL_TOUR_LINK_HTML),
          dissoc('tooltip'),
        )(newMarker);
      } else {
        setLinkTargetError('You need to select a target node in order to create a link.');
        return;
      }
    }

    onSubmit(newMarker);
  }

  const linkOptions = nodeList.map((v) => ({label: v.name, id: v.id}));
  const selectedValue = find(propEq('id', linkTarget), linkOptions);

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
      >
        <DialogTitle id="alert-dialog-title" style={{display: 'flex', justifyContent: 'space-between'}}>
          {isEdit ? 'Edit marker' : 'Create new marker.'}
          {isEdit && (
            <IconButton
              color="warning"
              onClick={() => setIsDeleteOpen(true)}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            You can either create a link to another node or an marker that provides information about a specific seen in the image.
          </DialogContentText>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={tab} onChange={(e, v) => setTab(v)}>
                <Tab label="Link" />
                <Tab label="Marker" />
              </Tabs>
            </Box>
            {tab === TAB_LINK && (
              <div>
                <Autocomplete
                  value={selectedValue}
                  options={nodeList.map((v) => ({label: v.name, id: v.id}))}
                  onChange={(e, v) => {
                    console.log(v);
                    setLinkTarget(v.id);
                    setLinkTargetError(null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="dense"
                      label="Target Node"
                      fullWidth
                      variant="outlined"
                      error={linkTargetError}
                    />
                  )}
                />
              </div>
            )}
            {tab === TAB_MARKER && (
              <div>
                <TextField
                  value={tooltip}
                  onChange={(e) => setTooltip(e.target.value)}
                  margin="dense"
                  label="Tooltip"
                  fullWidth
                  variant="outlined"
                />
              </div>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>
            {isEdit ? 'Submit Change' : 'Create Marker'}
          </Button>
        </DialogActions>
        <Dialog
          open={isDeleteOpen}
          keepMounted
          onClose={() => setIsDeleteOpen(false)}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>{tab === TAB_LINK ? 'Delete link' : 'Delete marker'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <p>Are you sure you want to delete?</p>
              <p>Deleting is permanent and can not be undone.</p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsDeleteOpen(false)}>Cancel</Button>
            <Button
              onClick={() => {
                onDelete();
                setIsDeleteOpen(false)
              }}
              color="warning"
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Dialog>
    </div>
  );
}


